.footer {
    width: 100%;
    height: 100%;
    background: #da251c;
    display: flex;
    padding: 30px 0px;
}

.footer span {
    color: #e0e0e0;
    width: 100%;
    text-align: center;
    font-size: 16px;
}

.payImg {
    width: 80px;
    height: auto;
}

.delivery {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
}

.footer_container {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.footer_logo {
    width: 150px;
    height: 100%;
}

.footer_logo img {
    width: 100%;
    height: auto;
}

.footer_item {
    width: 250px;
    height: auto;
    min-width: 250px;
}

.fotter_info {
    flex-grow: 1;
    height: auto;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.footer_item .title {
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 15px;
}

.footer_item .terminal {
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 15px;
}

.footer_item a {
    width: auto;
    height: auto;
    margin-bottom: 8px;
    display: flex;
    color: #e0e0e0;
}

.footer_item a img {
    width: auto;
    height: 50px;
    display: block;
}

.social {
    width: 100%;
    display: flex;
}

.social a {
    margin-right: 10px;
}

.social a img {
    width: 22px;
    height: 22px;
}

.docContainer {
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
}

.doc {
    color: #fff;
    font-size: 14px;
    padding: 5px 10px;
    cursor: pointer;
}

@media screen and (max-width: 1124px) {
    .fotter_info {
        padding-top: 20px;
    }
}

@media screen and (max-width: 840px) {
    .footer_item {
        margin-bottom: 25px;
    }
}
