.button {
    padding: 13px 33px;
    color: #fff;
    border-radius: 22px;
    background: #da251c;
    cursor: pointer;
    width: 100%;
    text-align: center;
    max-width: 200px;
    transition: 0.3s all;
    font-weight: 600;
    box-shadow: -1px 2px 15px 1px rgba(0, 0, 0, 0.09);
}
