.preloader::after { 
    position: relative;
    top: 0;
    bottom: 4%;
    right: 0;
    left: 0;
    
    content: " ";
    display: block;
    
    margin: auto;
    height: 42px;
    width: 42px;
    
    box-sizing: border-box;
    border: solid;
    border-width: 4px;
    border-radius: 50%;
    border-top-color: rgba(140, 140, 140, 0.55);
    border-bottom-color: rgba(140, 140, 140, 0.2);
    border-right-color: rgba(140, 140, 140, 0.2);
    border-left-color: rgba(140, 140, 140, 0.2);
    
    animation: rotating 0.9s linear infinite;
    z-index: 9999;
  }

  .preloaderContainer{
      padding: 0px 25px;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    overflow: auto;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: block;
}

.modal{
    height: 52px;
    width: 52px;
   
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
}
  
  @keyframes rotating{
    from {
      transform:rotate(0deg);
    }
    to {
      transform:rotate(360deg);
    }
  }
  