.overlay {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    overflow: auto;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.modal {
    max-width: 450px;
    width: 100%;
    height: auto;
    border-radius: 5px;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.modal_container {
    padding: 20px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
}
.modal_container img {
    width: 100px;
    height: 100px;
}

.modal_container h3 {
    text-align: center;
    width: 100%;
    padding: 25px 10px;
    color: #000;
}

.languages {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
}

.languages span,
.languages a {
    color: #000;
    font-size: 20px;
    font-weight: 800;
    padding: 10px;
    width: 100%;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s;
}

.languages span:hover,
.languages .active {
    color: #da251c;
}
