body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

:active, :hover, :focus {
  outline: 0;
  outline-offset: 0;
}

.row{
display: block;
padding: 0px 20px;
width: 100%;
height: auto;
flex-wrap: wrap;
}

a{
  text-decoration: none;
  color: #333;
}

h3{
  font-size: 24px;
  padding: 0px;
  margin: 0px;
  color: #333;
}

h4{
  font-size: 18px;
  padding: 0px;
  margin: 0px;
  color: #333;
}

.container{
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0px;
}

img{
  max-width: 100%;
  height: auto;
  border: 0;
  display: block;
}

.custom-container-classname{
  width: 22px;
}

.pdfBt{
    position: fixed;
    bottom: 18px;
    left: 18px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: rgb(239, 212, 183);
    z-index: 9999;
    box-shadow: -1px 2px 15px 1px rgba(0, 0, 0, 0.09);
    background-image: url('./assets/list.svg');
    background-position: center;
    background-size: 32px;
    background-repeat: no-repeat;
}