.delivery{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
}

.pickup{
    width: 100%;
    background: #fff;
    height: auto;
}

.pickup_title{
    font-size: 18px;
    color: #000;
    font-weight: 600;
    padding-bottom: 20px;
}

.pickup_info{
    width: 100%;
    height: 60px;
    box-shadow: -1px 2px 15px 1px rgba(0, 0, 0, 0.09);
    border-radius: 12px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    justify-content: space-between;
}

.pickup_info span{
    font-size: 16px;
    font-weight: 700;
    position: relative;
    display: flex;
    align-items: center;
    color: #333;
}

.pickup_info span::before{
    content: '';
    width: 22px;
    height: 22px;
    background: url('../../../assets/marker.svg');
    position: relative;
    background-size: contain;
    display: flex;
    margin-right: 8px;
    background-repeat: no-repeat;
}

.pickup_info .time {
    font-size: 13px;
    color: #333;
    display: flex;
    align-items: center;
    min-width: 105px;
}

.pickup_info .time::before{
    content: '';
    width: 19px;
    height: 19px;
    background: url('../../../assets/clock.svg');
    position: relative;
    background-size: contain;
    display: flex;
    margin-right: 8px;
    background-repeat: no-repeat;
}

.zones{
    width: 100%;
    height: auto;
    padding: 30px 0px;
}

.delivery_title {
    font-size: 20px;
    color: #000;
    font-weight: 600;
    padding-bottom: 8px;
}

.container_wrap {
    /* display: flex; */
    justify-content: space-between;
    margin-bottom: 16px;
}

.delivery_content {
    font-size: 18px;

}