.formContainer{
    max-width: 750px;
    display: block;
    flex-wrap: wrap;
    margin: auto;
    padding-top: 30px;
}

.formContainer form{
  padding-top: 15px;
  padding-bottom: 30px;
  display: block;
  width: 100%;
}

.buttonContainer{
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 15px;
}

.deliveryContainer{
  width: 100%;
  display: flex;
}

.item{
  display: flex;
  width: 50%;
  padding-right: 10px
}

.item span {
  white-space: nowrap;
}

.info{
  display: block;
  font-weight: 500;
  font-size: 18px;
  padding: 10px 15px;
}

.payForm{
  display: none;
}

.deliveryFieldWrapper {
  width: 100%;
}

.deliveryField {
  margin-top: 10px;
  margin-bottom: 15px;
  width: 100%;
  height: 40px;
  color: #333;
  font-size: 14px;
  border: 1px solid #796c60;
  background-color: #fff;
}

.messageError {
  display: block;
  color: red;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 10px;
}

@media screen and (max-width: 760px) {
  .deliveryContainer {
    flex-wrap: wrap;
  }
  .item {
    width: 100%;
  }
}