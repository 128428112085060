.header {
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    box-shadow: 0 10px 18px rgba(0, 0, 0, 0.2);
    position: sticky;
    top: 0px;
    z-index: 9999;
    background: #fff;
}

.header_nav {
    width: 100%;
    height: 80px;
    display: flex;
    background: #fff;
    align-items: center;
}

.burgerMenu {
    display: none;
}

.burgerMenu img {
    width: 35px !important;
    height: 35px !important;
}

.burgerMenuClose img {
    width: 30px !important;
    height: 30px !important;
}

.nav {
    width: auto;
    display: flex;
}

.nav a {
    padding-right: 15px;
    font-weight: 600;
    color: #333;
    white-space: nowrap;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo a {
    width: auto;
    width: auto;
    height: 55px;
    display: flex;
    align-items: center;
    margin-right: 30px;
}

.logo img {
    height: 100%;
    max-width: none;
    width: auto;
    padding: 5px;
}

.header_info {
    display: flex;
    width: 100%;
    flex-grow: 1;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
}

.info_container {
    height: auto;
    display: flex;
    width: auto;
    align-items: center;
    min-width: 200px;
}

.header_icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-position: center;
    background-size: 26px;
    background-repeat: no-repeat;
}

.phone {
    background-image: url(../../../assets/phone.svg);
}

.info {
    height: auto;
    padding-left: 8px;
    align-items: center;
    flex-grow: 1;
}

.info span {
    width: 100%;
    display: block;
}

.info .time {
    color: #333;
    font-size: 14px;
    font-weight: 400;
}

.info {
    color: #333;
    font-size: 14px;
    font-weight: 400;
}

.basket {
    width: 65px;
    height: 60px;
    background-position: center;
    background-size: 28px;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
    background-image: url(../../../assets/basket.svg);
}

.basket span {
    width: 22px;
    height: 22px;
    position: absolute;
    right: 7px;
    top: 9px;
    background: #da251c;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    color: #fff;
    font-weight: 600;
}

.menu_nav {
    width: 100%;
    height: 40px;
    background: #fff;
    display: flex;
    align-items: center;
}

.menu_nav span {
    color: #333;
    text-transform: uppercase;
    font-size: 14px;
    cursor: pointer;
    padding: 10px 10px 10px 0px;
}

.menu_nav span:hover,
.nav a:hover {
    transition: 0.3s all;
    color: #da251c;
}

.language {
    width: 65px;
    height: 60px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.language span {
    font-weight: 700;
    color: #333;
}

@media screen and (max-width: 840px) {
    .burgerMenu {
        display: block;
    }
    .nav {
        display: none;
    }
}

@media screen and (max-width: 540px) {
    .info_container .info {
        display: none;
    }
    .info_container {
        min-width: auto;
    }
}
