.inputWrapper{
    width: 100%;
    height: auto;
    display: flex;;
    flex-wrap: wrap;
}

.autocompleteContainer{
    width: 100%;
    height: auto;
    position: relative;
}

.inputWrapper label{
    width: 100%;
    display: block;
    font-size: 16px;
    color: #333;
}

.inputWrapper input, .time{
    margin-bottom: 15px;
    width: 100%;
    height: 40px;
    padding: 0px 10px;
    color: #333;
    font-size: 14px;
    border: none;
    border-bottom: 1px solid #796c60;
    background-color: #fff;
}

.inputWrapper textarea{
    margin-bottom: 15px;
    margin-top: 10px;
    color: #333;
    height: 60px;
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #796c60;
    resize: none;
    background-color: #fff;
}

.inputWrapper input[type="file" i] {
    width: 100%;
    border: 1px solid #796c60;
    padding: 12px 10px;
    font-size: 16px;
    height: auto;
}

.inputWrapper input[type="radio" i] {
    width: 100%;
    border: 1px solid #796c60;
    padding: 12px 10px;
    font-size: 16px;
    height: auto;
}

.inputRadio label{
    padding-bottom: 10px;
    display: block;
}

.inputRadio span{
    padding-left: 5px;
} 

.inputWrapper span{
    color: red;
    margin-bottom: 15px;
    font-weight: 400;
    padding: 0px 10px;
}

.inputWrapper select{
    margin-bottom: 15px;
    margin-top: 10px;
    width: 100%;
    height: 40px;
    padding: 0px 10px;
    color: #333;
    font-size: 14px;
    border: 1px solid #796c60;
    background-color: #fff;
}

.suggestion{
    width: 100%;
    padding: 10px;
    color: #333;
    cursor: pointer;
}

.suggestionsContainer{
    box-shadow: -1px 2px 15px 1px rgba(0, 0, 0, 0.09);
    padding: 10px 0px;
    margin-bottom: 10px;
    max-height: 200px;
    overflow: auto;
}