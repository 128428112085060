.menuWrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 9999;
}

.navMenu {
    position: relative;
    top: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.navMenu a {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 35px;
}

.navMenu a:hover {
    color: #da251c;
}
