.productsContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px;
}

.products_container {
    flex-grow: 1;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    padding-top: 0px;
}

.product_row {
    width: 100%;
    height: 100%;
    padding: 20px 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.product_img {
    width: 100%;
    height: 235px;
    background: #fff;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.product_img img {
    width: 100%;
    height: 100%;
    display: block;
}

.product_title {
    font-size: 18px;
    padding: 10px 0px;
    font-weight: 600;
    color: #000;
    cursor: pointer;
    text-align: center;
}

.product_description {
    font-size: 13px;
    color: #333;
    height: 50px;
    width: 100%;
    overflow: auto;
    margin-bottom: 10px;
    cursor: pointer;
    text-align: center;
}

.products_container .product {
    width: 33.3%;
    height: auto;
    min-width: 280px;
    background: #fff;
    transition: 0.3s all;
}

.product_footer {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.product_footer .price {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    width: 100%;
    text-align: center;
}

.count_product,
.add_product {
    width: 140px;
    height: 45px;
    border-radius: 22px;
    background: #da251c;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    z-index: 8888;
    margin-top: 15px;
    box-shadow: -1px 2px 15px 1px rgba(0, 0, 0, 0.09);
}

.count {
    border: 2px solid #da251c;
    color: #000;
    background: #fff;
}

.count .result {
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.count .plus,
.count .minus {
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s all;
    font-size: 18px;
    background-size: 15px;
    background-position: center;
    background-repeat: no-repeat;
}
.count .plus {
    border-top-right-radius: 22px;
    border-bottom-right-radius: 22px;
    background-image: url(../../assets/plus.svg);
}
.count .minus {
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
    background-image: url(../../assets/minus.svg);
}

.add_product span {
    display: flex;
    align-items: center;
}

.add_product span::before {
    content: "";
    position: relative;
    display: block;
    background-image: url(../../assets/add-to-cart.svg);
    width: 18px;
    height: 18px;
    background-size: contain;
    margin-right: 8px;
}

@media screen and (max-width: 1160px) {
    .products_container .product {
        width: 33.33%;
    }
}

@media screen and (max-width: 880px) {
    .products_container .product {
        width: 50%;
    }
}

@media screen and (max-width: 620px) {
    .products_container .product {
        width: 100%;
    }
}
