.container {
    font-size: 14px;
    font-weight: 500;
    padding: 15px 0 0 20px;
}

.breadCrumbs {
    display: flex;
    margin: 0;
    padding: 0;
}

.breadCrumbsItem {
    display: flex;
    /* align-items: center; */
    list-style-type: none;
    padding-right: 8px;
}

.breadCrumbsItem:last-child img {
    display: none;
}

.breadCrumbsItem:last-child a {
    font-weight: 500;
    color: #da251c;
}

.link {
    padding-right: 8px;
}

.link:hover {
    color: #da251c;
}

.arrow {
    position: relative;
    top: 7px;
    width: 7px;
    height: 7px;
    color: #444;
}
