.basketContainer{
    width: 100%;
    height: 100%;
    max-width: 1100px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
}

.basket{
    width: 100%;
    display: flex;
    height: auto;
    flex-wrap: wrap;
}

.product_list{
    width: 100%;
    height: auto;
    overflow: auto;
    max-height: 450px;
    height: auto;
}

.productList{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    padding: 20px 0px;
    max-width: 750px;
    align-items: baseline;
    margin: auto;
    min-height: 500px;
}

.basketFooter{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;
}

.basketFooter .title{
    width: 100%;
    text-align: center;
    padding: 15px;
    font-size: 22px;
    font-weight: 700;
    color: #000;

}

.warning{
    font-size: 18px;
    padding-top: 15px;
    width: 100%;
    color: #333;
}

.warningSumm{
    padding-bottom: 15px;
    padding-top: 0px;
    text-align: center;
    color: #333;
}
@media screen and (max-width: 720px) {
    .productList{
        max-width: 400px;
    }
}

