.sliderContainer{
    width: 100%;
    height: 100%;
    position: relative;
}


.sliderContainer .slider {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

.slideContainer{
    display: block;
    width: 100%;
    padding: 30px 20px;
}

.slide{
    width: 100%;
    height: auto;
}

.slide img{
    width: 100%;
    height: auto;
    display: block;
    border-radius: 20px;
}

.arrow{
    width: 45px;
    height: 45px;
    position: absolute;
    border-radius: 50%;
    z-index: 1;
    right: 3%;
    cursor: pointer;
    background-image: url(../../assets/next.svg);
    background-position-x: 15px;
    background-position-y: center;
    background-size: 18px;
    background-repeat: no-repeat;
    opacity: .65;
    background-color: #000;
  }
  
.arrow:nth-child(1){
    left: 3%;
    transform: rotate(180deg);
}

.button__bar{
    position: absolute;
    bottom: 0px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.button__bar li{
    position: relative;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.button__bar button{
    font-size: 0;
    line-height: 0;
    display: block;
    width: 14px;
    height: 14px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.button__bar li button:before{
    position: absolute;
    top: 0;
    left: 0;
    width: 14px;
    height: 14px;
    text-align: center;
    content: '';
    opacity: .25;
    background: #000;
    border-radius: 50%;
}

.button__bar li .slick_active::before{
    opacity: .65;
    background: #000;
}

@media screen and (max-width: 740px) {
    .slideContainer{
        padding: 15px 10px;
    }
    .button__bar{
       bottom: -5px;
    }
    .arrow{
        display: none;
    }
}