.img {
    width: 210px;
    height: 210px;
    margin: 30px auto;
}

.socialContainer {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.social {
    width: 40px;
    height: 40px;
    background-color: #724b99;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social img {
    width: auto;
    height: 20px;
}

.social:first-child {
    background-color: #2f5497;
}

.logo {
    width: 210px;
    height: auto;
    margin-bottom: 30px;
}

.container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    background: url(../../assets/mono_bg.png);
    background-size: cover;
    background-position: right;
    align-items: center;
}

.content {
    width: auto;
    height: auto;
    padding: 15px 50px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    max-width: 350px;
}

.button {
    display: block;
    height: auto;
    padding: 10px 20px;
    background: #da251c;
    border-radius: 8px;
}

.button p {
    padding: 0px;
    margin: 0px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
}

.button span {
    color: #fff;
    font-size: 22px;
    padding-top: 10px;
    font-weight: 700;
}

.title {
    width: 100%;
    font-weight: 500;
    font-size: 20px;
}

.description {
    width: 100%;
    font-size: 16px;
    color: "#2e2e2e";
}

.bt {
    margin-bottom: 30px;
}

@media screen and (max-width: 1124px) {
    .container {
        background-size: cover;
        background-position: unset;
    }
}
