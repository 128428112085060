.menuWrapper {
    position: fixed;
    width: 200px;
    height: 120px;
    top: 100px;
    left: calc(50% - 100px);
    background-color: white;
    box-shadow: 0px 10px 20px rgba(51, 51, 51, 0.5);
    border: 1px solid #f5f5f5;
    border-radius: 16px;
    z-index: 9999;
}

.info {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.info a {
    display: block;
    line-height: 30px;
}

.close {
    width: 20px;
    height: 20px;

    position: absolute;
    top: 1px;
    right: -30px;
}

.close svg {
    fill: #da251c;
}
