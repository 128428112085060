.categoriesContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    z-index: 999;
}

.categoriesContainer .row {
    position: relative;
}

.categories_container {
    width: 100%;
    height: auto;
    padding: 20px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.menu_categories {
    width: 100%;
    height: auto;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    margin-top: 32px;
    /* overflow-x: scroll;
    -webkit-overflow-scrolling: auto;
    -ms-overflow-x: scroll; */
}

.menu_categories span {
    padding: 15px 30px;
    border-radius: 22px;
    font-size: 14px;
    cursor: pointer;
    background: #da251c;
    /* box-shadow: inset 4px -6px 17px 9px rgba(0, 0, 0, 0.09); */
    margin: 0px 3px;
    color: #fff;
    flex: 0 0 auto;
    font-weight: 600;
    margin-bottom: 15px;
}

.menu_item {
    padding: 15px 30px;
    border-radius: 22px;
    font-size: 18px;
    cursor: pointer;
    background: #da251c;
    /* box-shadow: inset 4px -6px 17px 9px rgba(0, 0, 0, 0.09); */
    margin: 0px 3px;
    color: #fff;
    flex: 0 0 auto;
    font-weight: 600;
    margin-bottom: 15px;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 4px;
    background-color: rgba(218, 37, 28, 0.5);
}

::-webkit-scrollbar-thumb {
    background-color: rgba(218, 37, 28, 1);
}

.categories_container .active {
    transition: all 0.3s;
    background: rgba(218, 37, 28, 0.5);
    color: #fff;
    /* box-shadow: -1px 2px 15px 1px rgba(0, 0, 0, 0.09); */
}
