.delivery_title {
    font-size: 20px;
    color: #000;
    font-weight: 600;
    padding-bottom: 8px;
}

.aboutUs {
    /* height: calc(100vh - 332px); */
}

.container_wrap {
    /* display: flex; */
    justify-content: space-between;
    margin-bottom: 16px;
}

.delivery_content {
    font-size: 18px;

}

.link {
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 5px;
    text-decoration: underline;
}